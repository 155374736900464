import React, { useContext } from "react"
import { graphql } from "gatsby"
import { Container, Box, Stack, Text } from "@chakra-ui/react"
import { LocalizationContext } from "../context/LocalizationContext"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const IndexPage = ({ data, pageContext }) => {
  const daily = data.wpDaily
  const language = pageContext.language
  const { setLanguage } = useContext(LocalizationContext)
  setLanguage(language)

  let videoLink = daily.dailyBuilder.video
  if (videoLink) {
    const pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g
    const replacement = "https://www.youtube.com/embed/$1"
    if (pattern.test(videoLink)) {
      videoLink = videoLink.replace(pattern, replacement)
    }
  }

  return (
    <Layout language={language} overlayHeader={true}>
      <SEO
        lang={language}
        title={daily.title}
        alternates={pageContext.alternates}
      />
      <Stack spacing={24}>
        <Hero
          image={daily.featuredImage.node.localFile}
          title={daily.title}
          subTitle={daily.dailyBuilder.subtitle}
        />
        <Box>
          <Container maxWidth="5xl">
            <Text
              fontSize={{ md: "lg", lg: "xl" }}
              sx={{
                "ul, ol": { pl: 8, mb: 8 },
                "> :last-child": {
                  mb: 0,
                },
              }}
              dangerouslySetInnerHTML={{
                __html: daily.dailyBuilder.description,
              }}
            />
          </Container>
        </Box>
        {daily.dailyBuilder.images.map((media) => (
          <Box>
            <Container maxWidth="6xl">
              {media.image && (
                <Image fluid={media.image.localFile.childImageSharp.fluid} />
              )}
            </Container>
          </Box>
        ))}
        {videoLink && (
          <Box>
            <Container maxWidth="5xl">
              <iframe
                src={videoLink}
                allow="autoplay;"
                width="800"
                height="500"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                title="video"
              />
            </Container>
          </Box>
        )}
        <Box>
          <Container maxWidth="5xl">
            <Box>{daily.dailyBuilder.price}</Box>
            <Box>{daily.dailyBuilder.dates}</Box>
          </Container>
        </Box>
      </Stack>
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query($id: String) {
    wpDaily(id: { eq: $id }) {
      id
      slug
      title
      language {
        code
      }
      dailyBuilder {
        subtitle
        description
        price
        dates
        video
        images {
          image {
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 60) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
            colors {
              vibrant
              darkVibrant
              lightVibrant
              muted
              darkMuted
              lightMuted
            }
          }
        }
      }
    }
  }
`
